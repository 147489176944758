import {
    Box,
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import { defaultPagingOptions } from "common/utils";
import { FunctionComponent } from "react";
import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { ExportsTableProps, useExportsTable } from "./useExportsTable";
import { HAM_SUBLINES_EXPORTS_BUCKET_URL } from "configuration/config";
import useOpenNewTab from "hooks/useOpenNewTab";

/**
 * Renders the action buttons for the Exports Table.
 */
const ExportActions: FunctionComponent<{
    onExportAmazonian: () => void;
    onExportBulkOrdering: () => void;
    isLoading: boolean;
    openSubLineItemsFolder: () => void;
}> = ({ onExportAmazonian, onExportBulkOrdering, isLoading, openSubLineItemsFolder }) => (
    <SpaceBetween size="s" direction="horizontal">
        <Button
            data-testid="export-amazonian-orders-button"
            variant="normal"
            onClick={onExportAmazonian}
            loading={isLoading}
            disabled={isLoading}
        >
            {getString("exports.table.exportAmazonians")}
        </Button>
        <Button
            data-testid="export-bulk-ordering-button"
            variant="normal"
            onClick={onExportBulkOrdering}
            loading={isLoading}
            disabled={isLoading}
        >
            {getString("exports.table.exportBulkOrdering")}
        </Button>
        <Button
            data-testid="export-sub-line-items-button"
            variant="normal"
            onClick={openSubLineItemsFolder}
            loading={isLoading}
            disabled={isLoading}
        >
            {getString("exports.table.goToSubLineItemsFolder")}
        </Button>
    </SpaceBetween>
);

export const ExportsTable: FunctionComponent<ExportsTableProps> = (props) => {
    const openNewTab = useOpenNewTab();
    const { actions, state } = useExportsTable(props);
    const { doExportItemsAction, onPreferencesConfirmHandler } = actions;
    const {
        pagesCount,
        currentColumnDefinitions,
        currentPageIndex,
        currentPreferences,
        isReportLoading,
        loading,
        visibleContentColumnsOptions,
    } = state;

    // Handlers
    const handleExportAmazonian = () => doExportItemsAction("AMAZONIAN");
    const handleExportBulkOrdering = () => doExportItemsAction("ITSE");
    const handleOpenSubLineItemsFolder = () => openNewTab(HAM_SUBLINES_EXPORTS_BUCKET_URL);

    return (
        <Box data-testid="exports-box">
            <Table
                data-testid="exports-table"
                loading={loading}
                header={
                    <SpaceBetween size="m">
                        <Header
                            data-testid="exports-table-header"
                            variant="awsui-h1-sticky"
                            actions={
                                <ExportActions
                                    onExportAmazonian={handleExportAmazonian}
                                    onExportBulkOrdering={handleExportBulkOrdering}
                                    isLoading={isReportLoading}
                                    openSubLineItemsFolder={handleOpenSubLineItemsFolder}
                                />
                            }
                        >
                            {getString("exports.table.headerTitle")}
                        </Header>
                        <Box color="text-status-inactive">{getString("exports.table.headerDescription")}</Box>
                    </SpaceBetween>
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex.index}
                        pagesCount={pagesCount}
                        ariaLabels={{
                            nextPageLabel: getString("common.pagination.nextPage"),
                            previousPageLabel: getString("common.pagination.previousPage"),
                            pageLabel: (pageNumber: number) =>
                                getString("common.pagination.currentPage", undefined, { pageNumber, pagesCount }),
                        }}
                    />
                }
                empty={<EmptyTableBox />}
                stripedRows={currentPreferences.stripedRows}
                preferences={
                    <CollectionPreferences
                        title={getString("common.preferences.title")}
                        confirmLabel={getString("common.preferences.confirmLabel")}
                        cancelLabel={getString("common.preferences.cancelLabel")}
                        pageSizePreference={{
                            title: getString("common.preferences.pageSize.title"),
                            options: defaultPagingOptions,
                        }}
                        stripedRowsPreference={{
                            label: getString("common.preferences.stripedRows.label"),
                            description: getString("common.preferences.stripedRows.description"),
                        }}
                        visibleContentPreference={{
                            title: getString("common.preferences.visibleContentPreference.title"),
                            options: [
                                {
                                    label: getString("common.preferences.visibleContentPreference.columns"),
                                    options: visibleContentColumnsOptions,
                                },
                            ],
                        }}
                        preferences={currentPreferences}
                        onConfirm={onPreferencesConfirmHandler}
                    />
                }
                columnDefinitions={currentColumnDefinitions}
                loadingText={getString("common.loading")}
                items={[]}
                resizableColumns={true}
                selectionType="multi"
                variant="full-page"
                wrapLines
            />
        </Box>
    );
};

export default ExportsTable;
