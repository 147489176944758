import { SiteConfiguration } from "configuration/site-configuration";

const ADMIN_PREFIX = "hardware";
const BASE_DOMAIN = "procurement.it.a2z.com";

export const SYSTEM_FULFILL_NAME = "SYSTEM";
export const PROD_DOMAIN = ADMIN_PREFIX + "." + BASE_DOMAIN;
export const GAMMA_DOMAIN = ADMIN_PREFIX + ".gamma." + BASE_DOMAIN;
export const BETA_DOMAIN = ADMIN_PREFIX + ".beta." + BASE_DOMAIN;
export const DEV_DOMAIN = ADMIN_PREFIX + ".dev." + BASE_DOMAIN;
export const SANDBOX_DOMAIN = ADMIN_PREFIX + ".sandbox." + BASE_DOMAIN;
export const LOCAL_DOMAIN = ADMIN_PREFIX + ".local." + BASE_DOMAIN;

export class PERMISSIONS {
    public static DUMMY_BINDLELOCK = "this_is_a_dummy_bindle_lock";
    public static HARDWARE_PROCUREMENT = "hardware_procurement";
}

/**
 * Gets a configuration value depending on the environment stage.
 */
function getConfigByEnv(): SiteConfiguration {
    switch (location.hostname) {
        case PROD_DOMAIN:
            return require(`./config.prod.json`);
        case GAMMA_DOMAIN:
            return require(`./config.gamma.json`);
        case BETA_DOMAIN:
            return require(`./config.beta.json`);
        case DEV_DOMAIN:
            return require(`./config.dev.json`);
        case SANDBOX_DOMAIN:
            return require(`./config.sandbox.json`);
        default:
            return require(`./config.local.json`);
    }
}

const config = getConfigByEnv();
export const API_BASE_URL = config.apiBaseUrl;
export const CATALOGICAL_ENDPOINT = config.catalogicalEndpoint;
export const CATALOGICAL_ENDPOINT_V2 = config.apiBaseUrl + "/catalogical";
export const CATALOGICAL_EDIT_ITEM_URL = config.catalogicalEditItemUrl;
export const ITMPV1_WEBSITE_URL = config.itmpv1WebsiteUrl;
export const HAM_SUBLINES_EXPORTS_BUCKET_URL = config.hamSublinesExportsBucketUrl;