import { useCallback } from "react";

const useOpenNewTab = () => {
    return useCallback((url: string) => {
        if (!url) return;
        window.open(url, "_blank", "noopener,noreferrer");
    }, []);
};

export default useOpenNewTab;
