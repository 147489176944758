import { useEffect, useState } from 'react';
import {
    Box,
    SpaceBetween,
    FileUpload,
    Button,
    Header,
    FormField,
    Spinner,
} from '@amzn/awsui-components-react';

import { Order } from '@amzn/ito-client';

import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { getString } from 'common';
import { OrderAttachments } from './OrderSummaryTab/OrderAttachments';
import { useGetAttachmentsResponse, useUpdateOrdersStatus, useUploadFile } from 'hooks';

import ordersFieldConfig from "./OrderSummaryTab/orderDetails.form-config.json";
import { buildRenderedFormFields } from '../../../common/utils/form-utils';
import { ColumnFormSection } from './OrderSummaryTab/OrderDetailsSection';
import { CSRFService } from 'services/csrf-service';
import { EditableOrderDetails, useOrderSummaryForm } from './OrderSummaryTab/useOrderSummaryForm';
import { useOrderEditActions, useOrderEditItems } from 'views/OrdersDetailsPage/useOrderEdit';
import { debounce } from 'lodash';
import { hasAmazonianLabel } from '../../../helpers/helpers';
import { AMAZONIAN } from 'interfaces/constants';
const stringPath = "orderDetails.tabs.orderSummary";

interface OrderSummaryProps {
    order: Order,
    useOrderEditItems: useOrderEditItems,
    useOrderEditActions: useOrderEditActions,
    flashBarItemsActions: useFlashBarItemsActions,
    attachmentsHook: useGetAttachmentsResponse
}

export const OrderSummaryTab = (props: OrderSummaryProps) => {
    const order = props.order;
    const items = props.useOrderEditItems;
    const isAmazonian = hasAmazonianLabel(order, AMAZONIAN);

    const {
        isLoading: isPostAttachmentLoading,
        response: postAttachmentResponse,
        error: postAttachmentError,
        doUploadFile
    } = useUploadFile();

    const {
        isLoading: isLoadingOrderStatus,
        data,
        error: errorOrderStatus, doUpdateOrders
    } = useUpdateOrdersStatus();

    const { formValues, handleInputChange, getUpdatedFields, procurementMembers } = useOrderSummaryForm(order);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File[]>([]);
    const [isEditMode, setEditMode] = useState(false);

    let renderedFormFields: { [id: string]: JSX.Element } = buildRenderedFormFields<EditableOrderDetails>(
        ordersFieldConfig.fields,
        props.order,
        isEditMode,
        stringPath,
        formValues,
        handleInputChange,
        procurementMembers,
        setHasValidationErrors
    );

    // To trigger when data is updated
    useEffect(() => {
        if (data && !errorOrderStatus) {
            props.flashBarItemsActions.addSuccessFlashBarItem(`The order was updated correctly.`);
            props.useOrderEditActions.reloadOrder(order.orderId);
        }
        else if (errorOrderStatus) {
            props.flashBarItemsActions.addErrorFlashBarItem(`Error updating the order. ${errorOrderStatus.message}`);
        }
        setEditMode(false);
    }, [data, errorOrderStatus]);

    const onClickEdit = () => {
        setEditMode(true);
    }

    const onClickSubmit = () => {
        if (hasValidationErrors) {
            props.flashBarItemsActions.addErrorFlashBarItem("Please resolve all validation errors before submitting the order.");
            return;
        }
        const firstEditFlag = (props.useOrderEditItems.currentOrder?.details as any).firstEdit;

        const csrfService = new CSRFService();
        csrfService.getToken().then((token: string) => {
            doUpdateOrders(
                [order],
                getUpdatedFields(formValues, firstEditFlag === undefined),
                token);
        });
    }

    const uploadFile = async () => {
        const token = await new CSRFService().getToken();
        for (let i = 0; i < selectedFile.length; i++) {
            doUploadFile(props.order.orderId!, selectedFile[i], selectedFile[i].type, selectedFile[i].name, token)
        }
        setSelectedFile([]);
    }
    const debouncedUploadFile = debounce(uploadFile, 500)
    const handleUploadFile = () => {
        debouncedUploadFile();
    }
    // To trigger when data is updated
    useEffect(() => {
        if (postAttachmentResponse) {
            props.attachmentsHook.doGetAttachments();
        }
        if (postAttachmentError) {
            props.flashBarItemsActions.addErrorFlashBarItem(postAttachmentError.message);
        }
    }, [postAttachmentResponse, postAttachmentError]);

    function renderAttachments() {
        if (props.attachmentsHook.isLoading) {
            return (<Spinner />)
        } else {
            return (
                <OrderAttachments
                    attachments={props.attachmentsHook.data}
                    error={props.attachmentsHook.error}
                />
            )
        }
    }

    return (
        <Box padding={{ "top": "m" }} >
            <SpaceBetween size="xxl">
                <SpaceBetween data-testid="order-details-section" size="m">
                    <Header data-testid="order-details-header" variant="h1"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {isEditMode && <Box float="right">
                                    <SpaceBetween size="xs" direction="horizontal">
                                        <Button
                                            data-testid="cancel-edit-button"
                                            disabled={!isEditMode || isLoadingOrderStatus}
                                            variant="normal"
                                            onClick={() => setEditMode(false)}>Cancel</Button>
                                        <Button
                                            data-testid="submit-edit-button"
                                            disabled={!isEditMode || isLoadingOrderStatus}
                                            variant="primary"
                                            loading={isLoadingOrderStatus}
                                            onClick={onClickSubmit}>Submit</Button>
                                    </SpaceBetween>
                                </Box>}
                                {
                                    items.canBeEdited &&
                                    !isEditMode &&
                                    <Button data-testid="edit-order-button" disabled={isEditMode} onClick={onClickEdit}>Edit</Button>}
                            </SpaceBetween>

                        }
                    >{getString(`${stringPath}.orderDetails`)}
                    </Header>
                    <ColumnFormSection
                        columnsWithRows={[
                            [
                                "orderID",
                                "orderStatus",
                                "assignee"
                            ],
                            [
                                "requestType",
                                "requestCategories",
                                "totalQuantity"
                            ],
                            [
                                "requestedDate",
                                "expedited",
                                "age"
                            ]
                        ]}
                        renderedFormFields={renderedFormFields}
                    />
                </SpaceBetween>

                <SpaceBetween data-testid="requester-details-section" size="m">
                    <Header data-testid="requester-details-header" variant="h1">{getString(`${stringPath}.requesterDetails`)}</Header>
                    <ColumnFormSection
                        columnsWithRows={[
                            [
                                "name",
                                "alias"
                            ],
                            [
                                "country",
                                "building"
                            ],
                            [
                                "manager"
                            ]
                        ]}
                        renderedFormFields={renderedFormFields}
                    />
                </SpaceBetween>

                <SpaceBetween data-testid="shipping-details-section" size="m">
                    <Header
                        data-testid="shipping-details-header"
                        variant="h1"
                    >
                        {getString(`${stringPath}.shippingDetails`)}
                    </Header>
                    {isAmazonian ? (
                        <ColumnFormSection
                            columnsWithRows={[
                                ["amznhamDeliverAddressLineData", "amznhamDeliverCountry"],
                                ["amznhamDeliverCity", "amznhamDeliverPostalCode"],
                                ["amznhamDeliverState", "amznhamDeliverInstructions"]
                            ]}
                            renderedFormFields={renderedFormFields}
                        />
                    ) : (
                        <ColumnFormSection
                            columnsWithRows={[
                                ["deliverToBuilding", "deliverToRegion"],
                                ["deliverToCity"],
                                ["deliverToCountry"]
                            ]}
                            renderedFormFields={renderedFormFields}
                        />
                    )}
                </SpaceBetween>

                <SpaceBetween data-testid="financial-details-section" size="xs">
                    <Header data-testid="financial-details-header" variant="h1">{getString(`${stringPath}.financialDetails`)}</Header>
                    <ColumnFormSection
                        columnsWithRows={[
                            [
                                "costCenter",
                                "businessJustification",
                                "carCode"
                            ],
                            [
                                "projectCode",
                                "financialCode"
                            ],
                            [
                                "companyGlCode",
                                "locationGlCode"
                            ]
                        ]}
                        renderedFormFields={renderedFormFields}
                    />
                </SpaceBetween>

                <SpaceBetween data-testid="attachments-section" size="m">
                    <Header data-testid="attachments-header" variant="h1"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    data-testid="upload-attachment-button"
                                    variant="primary"
                                    onClick={handleUploadFile}
                                    disabled={isPostAttachmentLoading || selectedFile.length < 1}
                                    loading={isPostAttachmentLoading}
                                >
                                    {getString(`${stringPath}.upload`)}
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {getString(`${stringPath}.attachments`)}
                    </Header>
                    {renderAttachments()}
                    {items.canBeEdited && <FormField data-testid="upload-attachments-form">
                        <FileUpload
                            onChange={({ detail }) => setSelectedFile(detail.value)}
                            value={selectedFile}
                            i18nStrings={{
                                uploadButtonText: e => `Choose ${e ? "files" : "file"}`,
                                dropzoneText: e => `Drop ${e ? "files" : "file"} to upload`,
                                removeFileAriaLabel: e => `Remove file ${e + 1}`,
                                limitShowFewer: "Show fewer files",
                                limitShowMore: "Show more files",
                                errorIconAriaLabel: "Error"
                            }}
                            multiple
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                        />
                    </FormField>}
                </SpaceBetween>
            </SpaceBetween >
        </Box>
    );
}
