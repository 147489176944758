// REMOVE PENDING ONCE ALL ORDERS ARE OUT OF PENDING STATUS
export enum HardwareOrderStatus {
    ASSIGNED = "ASSIGNED",
    WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
    PENDING = "PENDING",
    PENDING_QUOTE = "PENDING_QUOTE",
    PENDING_REQ_INFO = "PENDING_REQ_INFO",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    PENDING_DELIVERY = "PENDING_DELIVERY",
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED",
    DENIED = "DENIED",
    APPROVED = "APPROVED",
    DELIVERED = "DELIVERED"
}